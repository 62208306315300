import React from "react";
import { Link } from "react-router-dom";
function Landing() {
  return (
    <div>
      <header id="header" class="fixed-top">
        <div class="container d-flex">
          <div class="logo mr-auto">
            <h1 class="text-light">
              <a href="/">
              <img src="/assets/images/logo.png" alt="" class="img-fluid" />
                <span>Yanni business solution pvt ltd</span>
              </a>
            </h1>
            
          </div>

          <nav class="nav-menu d-none d-lg-block">
            <ul>
              <li>
                <Link to="/login">Login</Link>
              </li>

              <li>
                <Link to="/register">Register</Link>
              </li>
            </ul>
          </nav>
        </div>
      </header>

      <section id="hero">
        <div class="container">
          <div class="row">
            <div
              class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"
              data-aos="fade-up"
            >
              <div>
                <h1>We develop digital products.</h1>
                <h2>.</h2>
              </div>
            </div>
            <div
              class="col-lg-6 order-1 order-lg-2 hero-img"
              data-aos="fade-left"
            >
              <img src="assets/img/hero-img.png" class="img-fluid" alt="" />
            </div>
          </div>
        </div>
      </section>

      <main id="main">
        <section id="about" class="about">
          <div class="container">
            <div class="row">
              <div class="col-lg-6" data-aos="zoom-in">
                <img src="assets/img/about.jpg" class="img-fluid" alt="" />
              </div>
              <div
                class="col-lg-6 d-flex flex-column justify-contents-center"
                data-aos="fade-left"
              >
                <div class="content pt-4 pt-lg-0">
                  <h3>Learn more about us</h3>
                  <p class="font-italic">
                    We make software solutions and also provide API services.
                  </p>
                  <ul>
                    <li>
                      <i class="icofont-check-circled"></i> We make mobile
                      applications.
                    </li>
                    <li>
                      <i class="icofont-check-circled"></i> We make websites.
                    </li>
                    <li>
                      <i class="icofont-check-circled"></i> We provide online.
                    </li>
                  </ul>
                  <p>
                    Our team provides a best software solution for companies and
                    also API Services
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="features" class="features">
          <div class="container">
            <div class="row">
              <div class="col-lg-6 mt-2 mb-tg-0 order-2 order-lg-1">
                <ul class="nav nav-tabs flex-column">
                  <li class="nav-item" data-aos="fade-up">
                    <a
                      class="nav-link active show"
                      data-toggle="tab"
                      href="#tab-1"
                    >
                      <h4>User Experience</h4>
                      <p>
                        We provide best user experience which makes the user
                        very attractive to the solution.
                      </p>
                    </a>
                  </li>
                  <li
                    class="nav-item mt-2"
                    data-aos="fade-up"
                    data-aos-delay="100"
                  >
                    <a class="nav-link" data-toggle="tab" href="#tab-2">
                      <h4>UI Design</h4>
                      <p>
                        Our Exciting desiging skills make the customer more
                        attractive to their solutions.
                      </p>
                    </a>
                  </li>
                  <li
                    class="nav-item mt-2"
                    data-aos="fade-up"
                    data-aos-delay="200"
                  >
                    <a class="nav-link" data-toggle="tab" href="#tab-3">
                      <h4>API Services</h4>
                      <p>
                        Our simple and efficent API services makes very easier
                        for the growth of your business.
                      </p>
                    </a>
                  </li>
                  <li
                    class="nav-item mt-2"
                    data-aos="fade-up"
                    data-aos-delay="300"
                  >
                    <a class="nav-link" data-toggle="tab" href="#tab-4">
                      <h4>Portfolio</h4>
                      <p>We make exciting portfolio for your business.</p>
                    </a>
                  </li>
                </ul>
              </div>
              <div class="col-lg-6 order-1 order-lg-2" data-aos="zoom-in">
                <div class="tab-content">
                  <div class="tab-pane active show" id="tab-1">
                    <figure>
                      <img
                        src="assets/img/features-1.png"
                        alt=""
                        class="img-fluid"
                      />
                    </figure>
                  </div>
                  <div class="tab-pane" id="tab-2">
                    <figure>
                      <img
                        src="assets/img/features-2.png"
                        alt=""
                        class="img-fluid"
                      />
                    </figure>
                  </div>
                  <div class="tab-pane" id="tab-3">
                    <figure>
                      <img
                        src="assets/img/features-3.png"
                        alt=""
                        class="img-fluid"
                      />
                    </figure>
                  </div>
                  <div class="tab-pane" id="tab-4">
                    <figure>
                      <img
                        src="assets/img/features-4.png"
                        alt=""
                        class="img-fluid"
                      />
                    </figure>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="services" class="services section-bg">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Services </h2>
              <p>
                We design and develop software solutions and provide API
                services to improve your business.
              </p>
            </div>

            <div class="row">
              <div
                class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
              >
                <div class="icon-box icon-box-pink">
                  <div class="icon">
                    <i class="bx bxl-dribbble"></i>
                  </div>
                  <h4 class="title">
                    <a href="">API SERVICES</a>
                  </h4>
                  <p class="description">
                    We provide API services to improve your business,Like
                    Messaging online.
                  </p>
                </div>
              </div>

              <div
                class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
                data-aos-delay="100"
              >
                <div class="icon-box icon-box-cyan">
                  <div class="icon">
                    <i class="bx bx-mobile"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Mobile Applications</a>
                  </h4>
                  <p class="description">
                    We develop mobile application for both platform
                  </p>
                </div>
              </div>

              <div
                class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
                data-aos-delay="200"
              >
                <div class="icon-box icon-box-green">
                  <div class="icon">
                    <i class="bx bx-tachometer"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Schedule</a>
                  </h4>
                  <p class="description">
                    We build Products within the given scedule.
                  </p>
                </div>
              </div>

              <div
                class="col-md-6 col-lg-3 d-flex align-items-stretch mb-5 mb-lg-0"
                data-aos="zoom-in"
                data-aos-delay="300"
              >
                <div class="icon-box icon-box-blue">
                  <div class="icon">
                    <i class="bx bx-world"></i>
                  </div>
                  <h4 class="title">
                    <a href="">Web Applications</a>
                  </h4>
                  <p class="description">
                    We develop web applications with various technology like
                    React , Laravel , Django.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="cta" class="cta">
          <div class="container">
            <div class="row" data-aos="zoom-in">
              <div class="col-lg-9 text-center text-lg-left">
                <h3>Call To Action</h3>
                <p>
                  {" "}
                  Please make a call and enquiry our services and that's all
                  welcome to family.
                </p>
              </div>
            </div>
          </div>
        </section>

        <section id="clients" class="clients">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Clients</h2>
              <p>
                These are our super clients who had a great experience with us.
              </p>
            </div>

            <div class="row no-gutters clients-wrap clearfix wow fadeInUp">
              <div class="col-lg-3 col-md-4 col-xs-6">
                <div class="client-logo" data-aos="zoom-in">
                  <img
                    src="assets/img/clients/client-1.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div
                  class="client-logo"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <img
                    src="assets/img/clients/client-2.jpeg"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div
                  class="client-logo"
                  data-aos="zoom-in"
                  data-aos-delay="150"
                >
                  <img
                    src="assets/img/clients/client-3.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div
                  class="client-logo"
                  data-aos="zoom-in"
                  data-aos-delay="200"
                >
                  <img
                    src="assets/img/clients/client-4.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>

              <div class="col-lg-3 col-md-4 col-xs-6">
                <div
                  class="client-logo"
                  data-aos="zoom-in"
                  data-aos-delay="250"
                >
                  <img
                    src="assets/img/clients/client-5.png"
                    class="img-fluid"
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="pricing" class="pricing section-bg">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Pricing</h2>
              <p>Our pricing details.</p>
            </div>

            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="box" data-aos="zoom-in">
                  <h3>API Services.</h3>
                  <h4>
                    <sup>RS</sup>699<span> / month</span>
                  </h4>
                  <ul>
                    <li>API Services</li>
                    <li>28 days</li>
                    <li>Managable dashboard</li>
                  </ul>
                  <div class="btn-wrap">
                    <Link to="/login" class="btn-buy">
                      Buy Now
                    </Link>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mt-4 mt-md-0">
                <div
                  class="box featured"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <h3>Mobile Application</h3>
                  <h4>
                    <sup>RS</sup>30000*<span> </span>
                  </h4>
                  <ul>
                    <li>Includes Server</li>
                    <li>Basic Aplication </li>
                    <li>No admin panel included</li>
                  </ul>
                  <div class="btn-wrap">
                    <Link to="/login" class="btn-buy">
                      Buy Now
                    </Link>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6 mt-4 mt-lg-0">
                <div class="box" data-aos="zoom-in" data-aos-delay="200">
                  <h3>Websites</h3>
                  <h4>
                    <sup>RS</sup>10000*<span> </span>
                  </h4>
                  <ul>
                    <li>Web Applications.</li>
                    <li>No admin panel included.</li>
                    <li>Intresting Design</li>
                  </ul>
                  <div class="btn-wrap">
                    <Link to="/login" class="btn-buy">
                      Buy Now
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section id="contact" class="contact section-bg">
          <div class="container">
            <div class="section-title" data-aos="fade-up">
              <h2>Contact Us</h2>
            </div>

            <div class="row">
              <div
                class="col-lg-7 mt-5 mt-lg-0  d-flex align-items-stretch"
                data-aos="fade-left"
              >
                <form method="post" role="form" class="php-email-form">
                  <div class="form-row">
                    <div class="form-group col-md-6">
                      <label for="name">Your Name</label>
                      <input
                        type="text"
                        name="name"
                        class="form-control"
                        id="name"
                        data-rule="minlen:4"
                        data-msg="Please enter at least 4 chars"
                      />
                      <div class="validate"></div>
                    </div>
                    <div class="form-group col-md-6">
                      <label for="name">Your Email</label>
                      <input
                        type="email"
                        class="form-control"
                        name="email"
                        id="email"
                        data-rule="email"
                        data-msg="Please enter a valid email"
                      />
                      <div class="validate"></div>
                    </div>
                  </div>
                  <div class="form-group">
                    <label for="name">Subject</label>
                    <input
                      type="text"
                      class="form-control"
                      name="subject"
                      id="subject"
                      data-rule="minlen:4"
                      data-msg="Please enter at least 8 chars of subject"
                    />
                    <div class="validate"></div>
                  </div>
                  <div class="form-group">
                    <label for="name">Message</label>
                    <textarea
                      class="form-control"
                      name="message"
                      rows="10"
                      data-rule="required"
                      data-msg="Please write something for us"
                    ></textarea>
                    <div class="validate"></div>
                  </div>
                  <div class="mb-3">
                    <div class="loading">Loading</div>
                    <div class="error-message"></div>
                    <div class="sent-message">
                      Your message has been sent. Thank you!
                    </div>
                  </div>
                  <div class="text-center">
                    <button type="submit">Send Message</button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer id="footer">
        <div class="footer-top">
          <div class="container">
            <div class="row">
              <div class="col-lg-3 col-md-6">
                <div class="footer-info">
              <img src="/assets/images/logo.png" alt="" class="img-fluid" />
                  <p>
                    <strong>Adress</strong> 555 Pallivilla, Vattappara TVM
                    Kerela 695028
                    <br />
                    <strong>Phone</strong> +91-04722586000
                    <br />
                    <strong>Email:</strong> support@ybspay.co.in
                    <br />
                    
                    <strong>Company Identity Number:</strong> U74999KL2017PTC050749
                    <br />
                    
                  </p>
                </div>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#">Web Design</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#">Web Development</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#">Mobile Applications</a>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <a href="#">API Services</a>
                  </li>
                </ul>
              </div>

              <div class="col-lg-3 col-md-6 footer-links">
                <h4>Our Policies</h4>
                <ul>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/privacy">Privacy Policy</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/terms">Terms and Conditions</Link>
                  </li>
                  <li>
                    <i class="bx bx-chevron-right"></i>{" "}
                    <Link to="/refund">Return and Refund Policy</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>

      <a href="#" class="back-to-top">
        <i class="ybx bxs-up-arrow-alt"></i>
      </a>
    </div>
  );
}

export default Landing;
