import React, { useEffect, useState } from 'react'
import LoaderComponent from '../Loader';
import useFetch from '../services/useFetch'
import SettlementVendor from './SettlementVendor';
import axios from "../axios"
function Settlements() {
    const {data:vendors,error,loading} = useFetch("/user/getVendorUsers");
    const [month,setMonth] = useState(new Date().getMonth());
    const [year,setYear] = useState(new Date().getFullYear())
//    const [vendorWithSettlements,setVendorWithSettlements] = useState({});
//    const updateData = (value)=>{
//        console.log("here")
//        setVendorWithSettlements(prevState=>{ return {...prevState,...value} });
//    }
    // useEffect(()=>{
    //     if(vendors){
    //         for(var v of vendors){
    //             axios({method:"get","url":"/user/vendorRenewedUsersDetails?id="+v.user_id}).then(res=>{
    //                 console.log(res.data)
    //                 if(res.data.status === 1){
    //                     var set={};
    //                     set[v.user_id] = res.data.data
    //                     updateData(set)
    //                     console.log(vendorWithSettlements)
    //                 }
    //             })
    //         }
    //     }
    // },[vendors])
    
   


    if (loading) return <LoaderComponent />
    return (
        <div>
      <div className='row'>

            <div className='col-sm-4'>
            <select onChange={(e)=>setMonth(e.target.value)} value={month} class="form-control" aria-label="Default select example">
  <option selected>Select Month</option>
  {["January","February","March","April","May","June","July","August","September","October","November","December"].map((item,index)=>
  
  <option value={index}>{item}</option>
  )} 
</select>
</div>
<div className='col-sm-4'>

<select  onChange={(e)=>setYear(e.target.value)} value={year} class="form-control" aria-label="Default select example">
  <option selected>Select Year</option>
  <option value={year-1}>{year-1}</option>
  <option value={year}>{year}</option>

  <option value={year+1}>{year+1}</option>

</select>

{/* <button  onClick={handleSearch} className="btn btn-success">Search</button> */}
            </div>
  </div>
            <table class="table mt-3">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">No: Renewed Users</th>
      <th scope="col">Settlement Amount</th>
      <th scope="col">Status</th>
      <th scope="col">Reference ID</th>
      <th scope="col">Action</th>

    </tr>
  </thead>
  <tbody>
    {vendors.map((v,index)=><SettlementVendor key={index} month={month} year={year} vendor={v}  />)}
  </tbody>
</table>
        </div>
    )
}

export default Settlements
