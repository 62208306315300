import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";
function Footer() {
  return (
    <footer>
      <div className="footer">
        <div className="footer__copyright">
          <p>
            {" "}
            Copyright © 2020 API HUB .This site is not a part of whatsapp and
            facebook website or facebook inc. Additionally this site is not
            endorsed by facebook in any way. facebook is a trademark of facebook
            inc.
          </p>
        </div>
        <div className="footer__terms">
          <div>
            <Link to="/terms">Terms</Link>
          </div>
          <div>
            <Link to="/privacy">Privacy</Link>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
