import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { Redirect } from 'react-router';
import {useParams,useLocation} from "react-router-dom"
import LoaderComponent from '../Loader';
import checkApiExpiry from '../services/checkApiExpiry';
import useFetch from '../services/useFetch';
import {useStateValue} from '../StateProvider';
import User from './User';
import VendorDashboard from './VendorDashboard';
export default function Users() {
    const [{userDetails},dispatch] = useStateValue();
    const [pagedUsers,setPagedUsers] = useState([]);
    const {role,id}= useParams();
    const query = useQuery();
    let url = parseInt(role) === 0 ? "admin" : "vendor?id="+id;
    const {data:users,error,loading} = useFetch("/user/"+url)
    let renewUserUrl = `vendorRenewedUsers${parseInt(role) === 0 ? '':"?id="+id}`
    const {data:renewedUsers,errorRenewed,loadingRenewed} = useFetch("/user/"+renewUserUrl)
    
    const [pageLimit,setPageLimit]=useState(10);
    const [filter,setFilter]=useState(query.get("filter"));
    const [filteredUsers,setFilteredUsers] = useState([]);
    const [length,setLength] = useState(0)
    useEffect(()=>{
     
     if(users && renewedUsers)
        {
          
            if(filter == '')    {
              setFilteredUsers(...users)
        setPagedUsers(users.slice(0,pageLimit))
    setLength(users.length)       
    }
        else{
          filterUsers(filter)
           

        }
    }
    },[users,pageLimit,query,filter,renewedUsers])
    const handlePageChange = (number)=>{
         setPagedUsers(filteredUsers.slice(number.selected*parseInt(pageLimit),number.selected*parseInt(pageLimit)+parseInt(pageLimit)))
    }
   async function filterUsers(value)
    {
      var newUsers = await getNewFilteredUsers(value);
      console.log("here",newUsers)
     
    await setFilteredUsers(newUsers);
    console.log(filteredUsers)

    var newPagedUsers =  newUsers.slice(0,pageLimit);
    setPagedUsers(newPagedUsers)
        setLength(filteredUsers.length)    
        console.log(newPagedUsers)
    }
   async function getNewFilteredUsers(value) {
    var newUsers ;
      if(value === "A"){
        newUsers = await users.filter((user)=> !checkApiExpiry(user.expiry_date))
 
     }else if(value === "ES")
     {
 newUsers = await users.filter((user)=> (!checkApiExpiry(user.expiry_date) && (user.expiry_date - Math.floor(Date.now() / 1000) <= 432000) ))
 
     }
     else if(value === "E"){
        newUsers = await users.filter((user)=> checkApiExpiry(user.expiry_date))
 
     }else if(value === "R"){
      newUsers = await users.filter((user)=> renewedUsers.includes(user.user_id))
 
     } else{
        newUsers =  users
 
     }
     return newUsers;
    }
    //  const handleFilterChange = (e)=>{
    //      setFilter(e.target.value)
    //    filterUsers(e.target.value);
         
    //  }

    if(loading) return <LoaderComponent/>
    // if(userDetails?.role === 2 ) return <Redirect to="/dashboard/" />
    if(error) return <h3>Error Fetching details</h3>
    return (
        <div>
            <h3>{filter === 'A' ? 'Active' : filter === 'E' ? "Expired" : filter ==='ES' ? "Expire with in 5 days " : 
            filter === 'R' ? "Renewed This Month" : ''
            }  User Details </h3>
            <div className="row">

            <div className="col-md-4">

            <div class="input-group mb-3">
  <div class="input-group-prepend">
    <label class="input-group-text" for="inputGroupSelect01">Show </label>
  </div>
  <select value={pageLimit} onChange={(e)=>setPageLimit(e.target.value)}  class="custom-select" id="inputGroupSelect01">
    {/* <option selected>Choose...</option> */}
    
    <option value="1">10</option>
    <option value="2">20</option>
    <option value="5">50</option>
    <option value="100">100</option>

  </select>
</div>
            </div>
            <div className="col-md-4">
                
{/* <div class="input-group mb-3">
  <select onChange={handleFilterChange} value={filter} class="custom-select" id="inputGroupSelect02">
    <option selected>Choose...</option>
    <option value="ALL">ALL Users</option>
    <option value="R">Renewed This Month</option>

    <option value="A">Active</option>
    <option value="E">Expired</option>
    <option value="ES">Expire within 5 days</option>
  </select>
  <div class="input-group-append">
    <label class="input-group-text" for="inputGroupSelect02">Filter API Expiry</label>
  </div>
</div> */}
</div>

            </div>
            <table class="table table-hover">
  <thead>
    <tr>
      <th scope="col">#</th>
      <th scope="col">Name</th>
      <th scope="col">Email</th>
      <th scope="col">Number</th>
      <th scope="col">Expiry</th>
     {userDetails?.role === 0  && <th scope="col">Commision</th>
}{userDetails?.role === 0  && <th scope="col">Action</th>
}
    </tr>
  </thead>
  <tbody>
    
            {pagedUsers.map((user,index)=><User user={user} index={index} key={index} />)}
      </tbody>
</table>
<ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={length/ pageLimit}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
        </div>
    )
}
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
