import Loader from "react-loader-spinner";

import React from "react";

function LoaderComponent() {
  return (
    <div>
      <Loader type="Rings" color="#00BFFF" height={80} width={80} />
    </div>
  );
}

export default LoaderComponent;
