import React from "react";
import { useStateValue } from "./StateProvider";
import axios from "./axios";
import "./Checkout.css";
import { useHistory } from "react-router-dom";
function loadRazorpay() {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = "https://checkout.razorpay.com/v1/checkout.js";
    script.onload = () => {
      resolve(true);
    };
    script.onerror = () => {
      resolve(false);
    };
    document.body.appendChild(script);
  });
}
function Checkout() {
  const history = useHistory();
  const [{ userDetails, selectedPlan }, dispatch] = useStateValue();
  async function displayRazorpay() {
    const res = await loadRazorpay();
    if (!res) {
      alert("Something went wrong ");
      return;
    } else {
      const data = {
        id: userDetails?.user_id,
        email: userDetails?.email,
        pid: selectedPlan?.id,
      };
      const res = await axios({
        method: "post",
        url: "/razorpay",
        data: data,
      });
      console.log(res.data);
      var options = {
        key:  "rzp_live_NdbRPDvmsp6PBo",//"rzp_live_ip86x7RHK1Amdr", //rfX5WvE881adBaWtZ4m3MZq0
        amount: res.data.amount,
        currency: res.data.currency,
        name: "API",
        description: "API Payment Transcation",
        image: "https://i.ibb.co/S0sfTVm/logo.png",
        order_id: res.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        handler: async function (response) {
          alert("Success");

          const response1 = await axios({
            method: "get",
            url: "/user",
            params: {
              id: userDetails?.user_id,
            },
          });
          console.log(response1.data);
          dispatch({
            type: "SET_USERDETAILS",
            userDetails: response1.data,
          });
          history.replace("/dashboard");
        },
        prefill: {
          name: userDetails?.name,
          email: userDetails?.email,
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    }
  }
  return (
    <div className="checkout">
      <div className="checkout__title">
        <h1>Checkout</h1>
      </div>
      <div className="checkout__container">
        <div className="checkout__body">
          <div className="checkout__planName">
            <p>Plan Name </p> <p>{selectedPlan?.name}</p>
          </div>
          <div className="checkout__amount">
            <p> Amount </p> <p> {selectedPlan?.amount}</p>{" "}
          </div>
          <div className="checkout__validity">
            <p> Validity </p>{" "}
            <p> {selectedPlan?.validity / (24 * 3600)} days </p>
          </div>
        </div>

        <div className="checkout__button">
          <button onClick={displayRazorpay}>Proceed to Pay</button>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
