import React, { useEffect, useState } from "react";
import "./Plans.css";
import { useStateValue } from "./StateProvider";
import axios from "./axios";
import LoaderComponent from "./Loader";
import PlanItem from "./PlanItem";
function Plans() {
  const [{ plans }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);

   useEffect(() => {
    setLoading(true);
    if (!plans) {
       axios({
        method: "post",
        url: "/plans",
      }).then((res)=>
      dispatch({
        type: "SET_PLAN",
        payload: res.data,
      }))
    }
    setLoading(false);
  }, []);
  
  return (
    <div className="plans">
      <div className="plans__title">
        <p>Pricing</p>
        <h2>Choose Suitable Plan For You</h2>
      </div>
      <div className="plans__item">
        {!plans ? (
          <LoaderComponent />
        ) : (
          plans?.map((item) => (
            <PlanItem
              id={item.id}
              amount={item.amount}
              name={item.name}
              description={item.description}
              validity={item.validity}
            />
          ))
        )}
      </div>
    </div>
  );
}

export default Plans;
