import React,{useState,useEffect} from 'react'
import timestampConvert from '../services/timeStampConvert';
import { useStateValue } from "../StateProvider";
import axios from "../axios";
const months = ["January","February","March","April","May","June","July","August","September","October","November","December"];
function VendorSettlementPage() {
    const [{userDetails},dispatch] = useStateValue();
    const [settlementDetails,setSettlementDetails] = useState(null)
 const [adminSettledDetails,setAdminSettledDetails] = useState(null)
 const [month,setMonth] = useState(new Date().getMonth());
 const [year,setYear] = useState(new Date().getFullYear())
 const [refId,setRefId] = useState('');

//    const {data:settlementDetails,error,loading} = useFetch("/user/vendorRenewedUsersDetails?id="+vendor.user_id+"&month="+month+"&year="+year);
    
      useEffect(()=>{
    if(userDetails){
            axios({method:"get","url":"/user/vendorRenewedUsersDetails?id="+userDetails?.user_id+"&month="+month+"&year="+year}).then(res=>{
                console.log(res.data)
                if(res.data.status === 1){
                    setSettlementDetails(res.data.data)
                }
            })
            getSettleData();
        
    }
},[userDetails,month,year])
const getSettleData = ()=>{
    
    axios({method:"get","url":"/settlement/?id="+userDetails?.user_id+"&month="+month+"&year="+year}).then(res=>{
        console.log(res.data)
        if(res.data.status === 1){
            setAdminSettledDetails(res.data.data)
            if(res.data.data.reference_id)setRefId(res.data.data.reference_id)
        }
    })
}
    var calaculateAmount = ()=>{
        var total=0;
        if(settlementDetails ){

            settlementDetails.map(i=>
                {
                    total+=(i.amount*(userDetails?.commision/100))
                    console.log("amount",i)}
                    )
                }
        return total.toFixed(2);
     }
// if(loading) return <LoaderComponent />
// if(error) return <h3>Error</h3>  
    return (
  
<div className='container '>

<div className='row'>
<div className="col-md-4">

        <select onChange={(e)=>setMonth(e.target.value)} value={month} class="form-control" aria-label="Default select example">
            <option selected>Select Month</option>
{months.map((item,index)=>

<option value={index}>{item}</option>
)} 
</select>
</div>
<div className='col-md-4'>

<select  onChange={(e)=>setYear(e.target.value)} value={year} class="form-control" aria-label="Default select example">
<option selected>Select Year</option>
<option value={year-1}>{year-1}</option>
<option value={year}>{year}</option>

<option value={year+1}>{year+1}</option>

</select>
</div>
</div>  
{/* <button  onClick={handleSearch} className="btn btn-success">Search</button> */}
        

      <h5 className='text-center mt-5' >Settlement Details for {months[month]}-{year}</h5>
<div className='container border rounded  p-3'>
      {/* <td scope="row">1</td> */}
      {/* <td>{vendor.name}</td> */}
    <div className='row'>
        <div className='col-sm-4'>

    <h6>No : of Customers Renewed : {settlementDetails?.length} </h6>
      
        </div>
        <div className='col-sm-4'>

    <h6>Total Settlement Amount : {      calaculateAmount()} </h6>
      
        </div>

        <div className='col-sm-4'>

    <h6  >Settlement Status : <span className={adminSettledDetails?.settled_date === 0 ? 'text-danger' : 'text-success'}>{adminSettledDetails?.settled_date === 0 ? 'Not settled' : "Settled" }</span> </h6>
      
      
        </div>
        </div>
        <div className='row mt-3'>
        {adminSettledDetails?.settled_date !== 0 && <>

            <div className='col-sm-4'>
            <h6  >Reference ID : {refId}</h6>
</div>
            <div className='col-sm-4'>
            <h6  >Settled Date : <strong>{timestampConvert(adminSettledDetails?.settled_date)}</strong></h6>
</div>
        </> }
    </div>
 
      {/* <td><button className="btn btn-success" onClick={handleSubmit} > Save </button></td> */}


    </div>
    </div>
    )
}


export default VendorSettlementPage
