import React from "react";
import { Link } from "react-router-dom";
import "./AddWhatsapp.css";
function AddWhatsapp() {
  return (
    <div className="addwhatsapp">
      <div className="addwhatsapp__container">
        <div className="addwhatsapp__title">No devices connected</div>
        <div className="addwhatsapp__connect">
          <Link to="/qrscan">
            <button>Add Device</button>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default AddWhatsapp;
