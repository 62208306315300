import React, { useEffect, useState } from "react";
import "./Qrscan.css";
import { useStateValue } from "./StateProvider";
import axios from "./axios";
import { Link, useHistory } from "react-router-dom";

function Qrscan() {
  var client;
  const [{ user, userDetails }, dispatch] = useStateValue();
  const [image, setImage] = useState();
  const [status, setStatus] = useState();
  const [loading, setLoading] = useState(false);
  const history = useHistory();

  /* client.onopen = () => {
    console.log("WebSocket Client Connected");
    var id = user.uid;

    client.send(JSON.stringify({ data: id }));
  };
  */

  /*client.onsuccess = (message) => {
    setStatus(true);
  };
  client.onfialed = (mesage) => {
    setStatus(false);
  }; */
  useEffect(async () => {
    if (user) {
      client = await new WebSocket("wss://online.apihub.co.in");
      client.onmessage = async (message) => {
        console.log(message);
        if (String(message.data) === "success") {
          client.close();
          setLoading(true);
          const data = {
            id: user.uid,
            status: 1,
          };
          const response = await axios({
            method: "post",
            url: "/changestatus",
            data: data,
          });
          console.log(response);
          var ud = userDetails;
          ud.active = 1;
          dispatch({
            type: "SET_USERDETAILS",
            userDetails: ud,
          });

          setStatus(message.data);
          // history.push("/");
          window.location = "https://apihub.co.in/dashboard";
        } else if (String(message.data) === "fail") {
          setStatus("Some thing went wrong please reload the page");
          client.close();
          //alert("Something had happend, please try again");
          //history.push("/dashboard");
          window.location = "https://apihub.co.in/dashboard";
        } else {
          setLoading(false);
          setImage(message.data);
        }
      };
      setLoading(true);
      client.onopen = (ev) => {
        client.send(user.uid);
      };
    }
  }, []);
  return (
    <div className="qrscan">
      <h5>Please scan this QR code through whatsapp</h5>
      {image ? (
        status != "success" ? (
          status != "fail" ? (
            <img src={"data:image/png;base64, " + image} />
          ) : (
            <></>
          )
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
      <h5>{status != null ? status : ""}</h5>
      {loading && <div>Loading</div>}
      {status == "success" ? (
        <Link to="/dashboard">
          <button>Go to API Documentation</button>
        </Link>
      ) : (
        <></>
      )}
    </div>
  );
}

export default Qrscan;
