import React, { useState } from "react";
import "./Dashboard.css";
import { timestampConvert } from "./reducer";
import { useStateValue } from "./StateProvider";
import axios from "./axios";
import LoaderComponent from "./Loader";
import VendorDashboard from "./adminComponents/VendorDashboard";

function Dashboard() {
  const [{ userDetails, user }, dispatch] = useStateValue();
  const [loading, setLoading] = useState();
  const setSideMenuActive = async (value) => {
    dispatch({
      type: "SET_SIDEMENU_ACTIVE",
      payload: value,
    });
  };
  const activateFreePlan = async () => {
    setLoading(true);
    const data = {
      id: userDetails.user_id,
    };
    const response = await axios({
      method: "post",

      url: "/changestatus/freeplan",
      data: data,
    });
    const response1 = await axios({
      method: "get",
      url: "/user",
      params: {
        id: user.uid,
      },
    });
    console.log(response.data);
    dispatch({
      type: "SET_USERDETAILS",
      userDetails: response1.data,
    });
    setLoading(false);
  };
  if(userDetails?.role === 1) return <VendorDashboard />
  return (
    <div className="dashboard">
      {userDetails?.expiry_date != 0 ? (
        userDetails?.expiry_date < Math.floor(Date.now() / 1000) ? (
          <div className="dashboard_NoactivePlan">
            <h3> No active plan for Whatsapp Bot</h3>
            <button onClick={() => setSideMenuActive(2)}>Buy PLan</button>
          </div>
        ) : (
          <div className="dashboard_activePlan">
            <h5>
              {" "}
              Whatsapp Bot Plan Expires on{" "}
              {timestampConvert(userDetails?.expiry_date)}{" "}
            </h5>
          </div>
        )
      ) : (
        <></>
      )}
      {userDetails?.expiry_date == 0 && (
        <div className="dashboard_NoactivePlan">
          <h3> No active plan</h3>
          <button onClick={() => setSideMenuActive(2)}>Buy PLan</button>
        </div>
      )}
      {userDetails?.api_expiry != 0 ? (
        userDetails?.api_expiry < Math.floor(Date.now() / 1000) ? (
          <div className="dashboard_NoactivePlan">
            <h3> No active plan for Offer API</h3>
            <button onClick={() => setSideMenuActive(2)}>Buy PLan</button>
          </div>
        ) : (
          <div className="dashboard_activePlan">
            <h5>
              {" "}
              Offer APi Plan Expires on{" "}
              {timestampConvert(userDetails?.api_expiry)}{" "}
            </h5>
          </div>
        )
      ) : (
        <></>
      )}
      {userDetails?.api_expiry == 0 && (
        <div className="dashboard_NoactivePlan">
          <h3> No active plan</h3>
          <button onClick={() => setSideMenuActive(2)}>Buy PLan</button>
        </div>
      )}
      {loading ? (
        <LoaderComponent />
      ) : (
        userDetails?.free_plan == 0 && (
          <div className="dashboard__freetrial">
            <div className="dashboard__freetrialtitle">
              <h3>Free Trial For 3 days</h3>
            </div>
            <div className="dashboard__freetrialDescription">
              <p>
                Get a Free trail For 3 days click on the button below to
                activate free trail and then go to manage and add your device
              </p>
            </div>
            <button onClick={activateFreePlan}>Activate Free Trail</button>
          </div>
        )
      )}
      <div className="dashboard__help">
        <div className="dashboard__helpTitle">
          <h3>Integration Steps</h3>
        </div>
        <div className="dashboard__helpStep">
          <ul>
            <li>
              <strong>Step 1:</strong>
              <p>Go to Manage Tab in the left corner</p>
            </li>

            <li>
              <strong>Step 2:</strong>
              <p>Then click Add Device Button</p>
            </li>

            <li>
              <strong>Step 3:</strong>
              <p>Wait for the QR to load and then scan the QR code </p>
            </li>

            <li>
              <strong>Step 4:</strong>
              <p>After QR code scanning Then go to API Documentation Tab.</p>
            </li>

            <li>
              <strong>Step 5:</strong>
              <p>Then copy the API url integrate. Its all done</p>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
