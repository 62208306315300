import React, { useState } from "react";
import "../Register.css";
import { firebaseApp } from "../firebaseSecondary";
import { Link, useHistory,useParams } from "react-router-dom";
import axios from "../axios";
import { useStateValue } from "../StateProvider";

function AddUser({isVendorUser}) {
    const [{}, dispatch] = useStateValue();
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const {role,id} = useParams();
  const clearFormData = ()=>{
      setEmail('');
      setName('');
      setPassword('');
      setNumber('')
  }
  const signUp = async (e) => {
    e.preventDefault();
    firebaseApp.auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (auth) => {
        setLoading(true);
        console.log(auth);
        let data = {
            id: auth.user.uid,
            name: name,
            email: email,
            number: number,
        };
        if(isVendorUser)data={...data,isVendor:true};
        if(parseInt(role) === 1)data={...data,vendor:id};
        const response = await axios({
          method: "post",
          url: "/user",
          data: data,
        });
        console.log(response);
        alert(response.data);
        clearFormData()
      })
      .catch((error) => setError(error.message)).finally(()=>  {
        //    auth.signOut()
        firebaseApp.auth().signOut();
        setLoading(false)});

  };
  return (
    <div className="register">
      <div className="register__container">
        
        <div className="register__title">
          <h3>Add {isVendorUser ? 'Vendor' : "User"}</h3>
        </div>
        <div className="register__form">
          {error && (
            <div className="register__error">
              <p>{error}</p>
            </div>
          )}
          <form>
            <h6>Name</h6>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <h6>Email</h6>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <h6>Whatsapp Number</h6>
            <input
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
            <h6>Password</h6>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="register__signInContainer">
              <button
                onClick={signUp}
                type="submit"
                className="register__signInButton"
              >
                {loading ? "Loading" : `Add ${isVendorUser ? 'Vendor' : 'User'}` }
              </button>
            </div>
          </form>
        </div>

       
      </div>
    </div>
  );
}

export default AddUser
