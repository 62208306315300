import React, { useState } from "react";
import AddWhatsapp from "./AddWhatsapp";
import "./Manage.css";
import ManageDevice from "./ManageDevice";
import { useStateValue } from "./StateProvider";
import axios from "./axios";

function Manage() {
  const [{ userDetails }, dispatch] = useStateValue();
  const [ip, setIp] = useState();
  const [error, setError] = useState();
  const [loading, setLoading] = useState();
  const addIp = async (e) => {
    e.preventDefault();
    if (ip == null) {
      setError("Please Enter Ip");
    } else {
      var data = {
        id: userDetails?.user_id,
        ip: ip,
      };
      var response = axios({
        method: "post",
        url: "/changestatus/addip",
        data: data,
      });
      const response2 = await axios({
        method: "get",
        url: "/user",
        params: {
          id: userDetails.user_id,
        },
      });
      dispatch({
        type: "SET_USERDETAILS",
        userDetails: response2.data,
      });
    }
  };
  const deleteIp = async (e) => {
    e.preventDefault();
    var data = {
      id: userDetails?.user_id,
      ip: null,
    };
    var response = axios({
      method: "post",
      url: "/changestatus/addip",
      data: data,
    });
    const response2 = await axios({
      method: "get",
      url: "/user",
      params: {
        id: userDetails.user_id,
      },
    });
    dispatch({
      type: "SET_USERDETAILS",
      userDetails: response2.data,
    });
  };
  return (
    <div className="manage">
      <div className="manage__container">
        <h4>Manage Devices</h4>
        <h2>WhatsApp BOT Setting</h2>
        <div className="manage__deviceBox">
          <div className="manage__deivce">
            {userDetails?.active == 1 ? <ManageDevice /> : <AddWhatsapp />}
          </div>
          <div className="manage__ip">
            {userDetails?.ip == null ? (
              <div className="manage__noip">
                <h4>No IP Address Added.</h4>
                <form>
                  <input
                    className="form-control"
                    onChange={(e) => setIp(e.target.value)}
                    value={ip}
                  />
                  <button
                    onClick={addIp}
                    className="manage__adip"
                    type="submit"
                  >
                    Add
                  </button>
                </form>
              </div>
            ) : (
              <div className="manage__ipDetails">
                {" "}
                <h3> your current Ip {userDetails?.ip} </h3>{" "}
                <button
                  onClick={deleteIp}
                  className="manage__adip"
                  type="submit"
                >
                  Remove
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Manage;
