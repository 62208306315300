import React, { useState } from "react";
import "./Header.css";
import { useStateValue } from "./StateProvider";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { ExitToAppOutlined, Settings } from "@material-ui/icons";
import { auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";
function Header() {
  const [{ userDetails }, dispatch] = useStateValue();
  const [dropdown, setDropdown] = useState(false);
  const history = useHistory();
  const logOut = () => {
    auth.signOut();
    history.replace("/login");
  };
  return (
    <div className="header">
      <div className="header__logo">
        <Link to="/dashboard">
          {" "}
          <img className="header__logoImg" src="assets/images/logo.png" />{" "}
        </Link>
      </div>
      <div className="header__userInfo">
        <div
          onClick={() => setDropdown(!dropdown)}
          className="header__userName"
        >
          <p>Hello, {userDetails?.name} </p>
        </div>
        <div onClick={logOut} className="header__dropDownItem">
          <ExitToAppOutlined />
          {"  "}
          <p>Logout</p>{" "}
        </div>
      </div>
    </div>
  );
}

export default Header;
