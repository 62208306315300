import axios from "../axios"
import React, { useState } from 'react'
import timeStampConvert from '../services/timeStampConvert'

function User({user,index}) {
  const [commision,setCommision] = useState(user.commision);
  const handleSubmit = (e)=>{
    e.preventDefault();
    axios({method:"post",url:"/user/setCommision",data:{id:user.user_id,commision}}).then((res)=>{console.log(res.data)}).catch((err)=>console.log(err))
  }
    return (
        <tr>
      <td scope="row">{index+1}</td>
      <td>{user.name}</td>
      <td>{user.email}</td>
      <td>{user.number}</td>
      <td>{timeStampConvert(user.expiry_date)}</td>
      {/* <form  onSubmit={handleSubmit}> */}
      { user.role === 1 && <>
      <td className="col-sm-1"><input className="form-control " type="text" value={commision} onChange={(e)=>setCommision(e.target.value)} /></td>
      <td><button onClick={handleSubmit} className="btn btn-success"  type="submit">Save</button></td>
      </> }
      {/* </form> */}
    </tr>
    )
}

export default User
