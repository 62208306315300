import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "./SideMenu.css";
import { useStateValue } from "./StateProvider";
function SideMenu() {
  const [{ sideMenuActive,userDetails }, dispatch] = useStateValue();
  const setSideMenuActive = async (value) => {
    dispatch({
      type: "SET_SIDEMENU_ACTIVE",
      payload: value,
    });
  };

  const adminAndVendorNavs = () =>{
    return <><NavLink
    to={"/dashboard/users/"+userDetails?.role+"/"+userDetails?.user_id}
    activeClassName=
           "sidemenu__itemActive"
           
         className= 
          "sidemenu__itemDisabled d-block"
    >
      Users
      </NavLink>
      <NavLink
    to={"/dashboard/adduser/"+userDetails?.role+"/"+userDetails?.user_id}
    activeClassName=
           "sidemenu__itemActive"
           
         className= 
          "sidemenu__itemDisabled d-block"
    >
      Add User
      </NavLink>
 {userDetails?.role === 1 &&      <NavLink
    to={"/dashboard/vendorSettlements"}
    activeClassName=
           "sidemenu__itemActive"
           
         className= 
          "sidemenu__itemDisabled d-block"
    >
      My Settlements
      </NavLink> }
    {userDetails?.role === 0 &&   <NavLink
    to={"/dashboard/addvendor/"}
    activeClassName=
           "sidemenu__itemActive"
           
         className= 
          "sidemenu__itemDisabled d-block"
    >
      Add Vendor
      </NavLink>} 
      {userDetails?.role === 0 &&   <NavLink
    to={"/dashboard/settlements/"}
    activeClassName=
           "sidemenu__itemActive"
           
         className= 
          "sidemenu__itemDisabled d-block"
    >
      Settlements
      </NavLink>}
      </>
  }
  return (
    <div className="sidemenu">
      <div className="sidemenu__container">
        <NavLink
        exact
        to="/dashboard/"
        activeClassName=
               "sidemenu__itemActive"
               
             className= 
              "sidemenu__itemDisabled d-block"
        >
          Dashboard
        </NavLink>
        <NavLink
        to="/dashboard/manage"
        activeClassName=
               "sidemenu__itemActive"
               
             className= 
              "sidemenu__itemDisabled d-block"
        >
          Manage
        </NavLink>

        <NavLink
        to="/dashboard/plans"
        activeClassName=
               "sidemenu__itemActive"
               
             className= 
              "sidemenu__itemDisabled d-block"
        >
          Buy Plans
    
          </NavLink>

        <NavLink
        to="/dashboard/paymenthistory"
        activeClassName=
               "sidemenu__itemActive"
               
             className= 
              "sidemenu__itemDisabled d-block"
        >
          Payments History
          </NavLink>

        <NavLink
        to="/dashboard/apidocumentation"
        activeClassName=
               "sidemenu__itemActive"
               
             className= 
              "sidemenu__itemDisabled d-block"
        >
          API Documentation
          </NavLink>


        <NavLink
        to="/dashboard/messages"
        activeClassName=
               "sidemenu__itemActive"
               
             className= 
              "sidemenu__itemDisabled d-block"
        >
          Outgoing Messages
          </NavLink>
          {userDetails?.role !== 2 && adminAndVendorNavs()}
      </div>
    </div>
  );
}

export default SideMenu;
