// For Firebase JS SDK v7.20.0 and later, measurementId is optional
import firebase from "firebase";

// const firebaseConfig = {
//   apiKey: "AIzaSyBez1AE8HNMi5URu3mWbTvnDPe_8rTLGHE",
//   authDomain: "whatsapp-api-e5cbb.firebaseapp.com",
//   databaseURL: "https://whatsapp-api-e5cbb.firebaseio.com",
//   projectId: "whatsapp-api-e5cbb",
//   storageBucket: "whatsapp-api-e5cbb.appspot.com",
//   messagingSenderId: "261893723921",
//   appId: "1:261893723921:web:5e1f522efd4abb6b04deb0",
//   measurementId: "G-28ZZCXXLG2",
// };
const firebaseConfig = {
  apiKey: "AIzaSyA_T1QaQDyPMXnKiJlVDui2pLowbKxEIrI",
  authDomain: "whatsapp-api-vendor.firebaseapp.com",
  projectId: "whatsapp-api-vendor",
  storageBucket: "whatsapp-api-vendor.appspot.com",
  messagingSenderId: "16639379982",
  appId: "1:16639379982:web:8dcf7241abe351de27ca5f"
};
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();

export { db, auth };
