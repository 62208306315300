import { Delete } from "@material-ui/icons";
import React, { useState } from "react";
import "./ManageDevice.css";
import { useStateValue } from "./StateProvider";
import axios from "./axios";
import Loader from "./Loader";
function ManageDevice() {
  const [{ userDetails }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(false);
  const handleDelete = async () => {
    setLoading(true);
    const data = {
      id: userDetails.user_id,
      status: 0,
    };
    const response = await axios({
      method: "post",
      url: "/changestatus",
      data: data,
    });
    var ud = userDetails;
    ud.active = 0;
    dispatch({
      type: "SET_USERDETAILS",
      userDetails: ud,
    });
    setLoading(false);
  };
  return (
    <>
      <div className="manageDevice">
        <div className="manageDevice__title">
          <h4>{userDetails?.name}</h4>
        </div>
        <>
          {loading ? (
            <Loader />
          ) : (
            <div className="manageDevice__body">
              <p>Active</p>

              <div onClick={handleDelete}>
                {" "}
                <Delete />
                Delete
              </div>
            </div>
          )}
        </>
      </div>
    </>
  );
}

export default ManageDevice;
