import React, { useEffect,useState } from "react";
import "./PaymentHistory.css";
import { useStateValue } from "./StateProvider";
import axios from "./axios";
import { timestampConvert } from "./reducer";
import LoaderComponent from "./Loader";
function PaymentHistory() {
  const [{ paymentHistory, userDetails }, dispatch] = useStateValue();
  const [loading, setLoading] = useState(true);

  useEffect( ()=>{
    const data = {
          id:userDetails?.user_id,
        };
        console.log("user d ",data)
        console.log("user response ", data);
         axios({
          method: "post",
          url: "/razorpay/payments",
          data: data,
        }).then((response1)=>{
        dispatch({
          type: "SET_PAYMENTHISTORY",
          payload: response1.data,
        });
        setLoading(false)
      }
        )

  },[])
  return (
    <div className="paymenthistory">
      <div className="paymenhistory__title">
        <h2>Payment History</h2>
      </div>
       {loading ? <LoaderComponent/> : 
      <div className="paymenthistory__data">
        {paymentHistory.length == 0 ? (
          <h3>No Items</h3>
        ) : (
          <table>
            <tr>
              <th>Sl No</th>
              <th>Amount</th>
              <th>Date</th>
              <th>Status</th>
            </tr>
            {paymentHistory?.map((item, i) => (
              <tr>
                <td>{i + 1}</td>
                <td>{item.amount}</td>
                <td>{timestampConvert(JSON.parse(item.data).created_at)}</td>
                <td>
                  {JSON.parse(item.data).captured ? (
                    <div className="success">Success</div>
                  ) : (
                    <div className="failed">Failed</div>
                  )}{" "}
                </td>
              </tr>
            ))}
          </table>
        )}
      </div>
}
    </div>
                  
  );
}

export default PaymentHistory;
