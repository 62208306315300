import React from "react";
import { useHistory } from "react-router-dom";
import "./PlanItem.css";
import { useStateValue } from "./StateProvider";
function PlanItem({ id, name, amount, validity, description }) {
  const [{}, dispatch] = useStateValue();
  const history = useHistory();

  const convertValidity = (value) => {
    var val = 24 * 3600;
    return value / val;
  };
  const handleSelect = (value) => {
    const data = {
      id: id,
      name: name,
      amount: amount,
      validity: validity,
      description: description,
    };
    dispatch({
      type: "SET_SELECTED_PLAN",
      payload: data,
    });
    history.push("/checkout");
  };
  return (
    <div className="planItem">
      <div className="planItem__name">
        <div>{name}</div>
      </div>
      <div className="planItem__description">
        {description.split("<br>").map((item) => (
          <>
            <li type="none">{item}</li>
            <br />{" "}
          </>
        ))}
      </div>
      <div className="planItem__price">Rs {amount}</div>
      <div className="planItem__validity">
        {convertValidity(validity)} / Days
      </div>
      <div className="planItem__select">
        <button onClick={handleSelect}>Select</button>
      </div>
    </div>
  );
}

export default PlanItem;
