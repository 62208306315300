import { auth } from "./firebase";
import React, { useEffect } from "react";
import {
  Route,
  BrowserRouter as Router,
  Switch,
  useHistory,
} from "react-router-dom";
import "./App.css";
import Header from "./Header";
import Login from "./Login";
import Register from "./Register";
import { useStateValue } from "./StateProvider";
import axios from "./axios";
import Home from "./Home";
import Qrscan from "./Qrscan";
import Checkout from "./Checkout";
import Redirect from "./Redirect";
import Dashboard from "./Dashboard";
import Footer from "./Footer";
import Terms from "./Terms";
import PrivacyPolicy from "./PrivacyPolicy";
import Landing from "./Landing";
import RefundPolicy from "./refundpolicy";
function App() {
  const history = useHistory();
  const [{ userDetails, user }, dispatch] = useStateValue();
  useEffect(() => {
    auth.onAuthStateChanged(async (authUser) => {
      console.log("user is ", authUser);
      if (authUser) {
        dispatch({
          type: "SET_USER",
          user: authUser,
        });
        const response = await axios({
          method: "get",
          url: "/user",
          params: {
            id: authUser.uid,
          },
        });
        console.log(response.data);
        dispatch({
          type: "SET_USERDETAILS",
          userDetails: response.data,
        });
        
    
              } else {
        dispatch({
          type: "SET_USER",
          user: null,
        });
        dispatch({
          type: "SET_USERDETAILS",
          userDetails: null,
        });
      }
    });
  }, []);
  return (
    <Router>
      <div className="app">
        <Switch>
          <Route path="/login">
            <>
              {" "}
              <Login /> <Footer />{" "}
            </>
          </Route>

          <Route path="/register">
            <>
              {" "}
              <Register /> <Footer />{" "}
            </>
          </Route>
          <Route path="/qrscan">
            {user ? (
              <>
                {" "}
                <Header />
                <Qrscan />
                <Footer />
              </>
            ) : (
              <>
                {" "}
                <Login /> <Footer />{" "}
              </>
            )}
          </Route>
          <Route path="/checkout">
            {user ? (
              <>
                {" "}
                <Header />
                <Checkout />
                <Footer />{" "}
              </>
            ) : (
              <>
                {" "}
                <Login /> <Footer />{" "}
              </>
            )}
          </Route>
          <Route path="/terms">
            <Terms />
          </Route>

          <Route path="/refund">
            <RefundPolicy />
          </Route>
          <Route path="/privacy">
            <PrivacyPolicy />
          </Route>
          <Route path="/dashboard">
            {user ? (
              <>
                <Header /> <Home /> <Footer />{" "}
              </>
            ) : (
              <>
                {" "}
                <Login /> <Footer />{" "}
              </>
            )}
          </Route>
          <Route
            path="/"
            render={() => (
              <>
                <Landing />{" "}
              </>
            )}
          />
        </Switch>
      </div>
    </Router>
  );
}

export default App;

//import React, { Component } from "react";
//import "./App.css";

//const client = new WebSocket('ws://localhost:8002');
//class App extends Component {
/* constructor(props) {
    super(props);
    this.state = {
      img: "",
    };
  }
  componentWillMount() {
    client.onopen = () => {
      console.log("WebSocket Client Connected");
      client.send("Hi this is web client.");
    };
    client.onmessage = (message) => {
      console.log(message);
      this.setState({ img: message.data });
    };
  }

  render() {
    return (
      <div>
        Practical Intro To WebSockets.
        <img src={"data:image/png;base64, " + this.state.img} />
      </div>
    );
  }
}*/

//export default App;
