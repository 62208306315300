import React, { useState } from "react";
import "./Login.css";
import { auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";
function Login() {
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [error, setError] = useState();
  const signIn = (e) => {
    e.preventDefault();
    auth
      .signInWithEmailAndPassword(email, password)
      .then((auth) => {
        history.push("/dashboard");
      })
      .catch((error) => setError(error.message));
  };
  const forgotPassword = () => {
    auth
      .sendPasswordResetEmail(email)
      .then((auth) => {
        alert("done");
      })
      .catch((error) => setError(error.message));
  };
  return (
    <div className="login">
      <div className="login__container">
        <div className="login__logo">
          <img src="assets/images/logo.png" height="60" />
        </div>
        <div className="login__title">
          <h3>Sign-In</h3>
        </div>
        <div className="login__form">
          {error && (
            <div className="login__error">
              <p>{error}</p>
            </div>
          )}
          <form>
            <h6>Email</h6>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <h6>Password</h6>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="login__signInContainer">
              <button
                onClick={signIn}
                type="submit"
                className="login__signInButton"
              >
                Sign In
              </button>
            </div>
          </form>
        </div>

        <div className="login__forgotPassword">
          <p onClick={forgotPassword}>Forgot password?</p>
        </div>
        <div className="login__register">
          <p>
            I dont have an account?{" "}
            <Link className="login__registerText" to="/register">
              <strong>Register Here</strong>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Login;
