export const initialState = {
  user: null,
  userDetails: null,
  sideMenuActive: 0,
  plans: null,
  selectedPlan: null,
  paymentHistory: null,
  messages: [],
  count: 0,
  active: null,
};
export const timestampConvert = (value) => {
  console.log(value);
  var t = new Date(0);
  t.setMilliseconds(value * 1000);
  //var formatted = t.format("dd.mm.yyyy ");
  var sdtr = t.toDateString();
  //  t.getUTCDate() + "/" + t.getUTCMonth() + "/" + t.getUTCFullYear();
  return sdtr;
};
export const timeandDateConvert = (value) => {
  console.log(value);
  var t = new Date(0);
  t.setMilliseconds(value * 1000);
  //var formatted = t.format("dd.mm.yyyy ");
  return (
    t.getUTCDate() +
    "/" +
    t.getUTCMonth() +
    "/" +
    t.getUTCFullYear() +
    "  " +
    t.getHours() +
    ":" +
    t.getMinutes() +
    ":" +
    t.getSeconds()
  );
};
const reducer = (state, action) => {
  console.log(action);
  switch (action.type) {
    case "SET_USER":
      return {
        ...state,
        user: action.user,
      };
    case "SET_USERDETAILS":
      return {
        ...state,
        userDetails: action.userDetails,
      };
    case "SET_SIDEMENU_ACTIVE":
      return {
        ...state,
        sideMenuActive: action.payload,
      };
    case "SET_PLAN":
      return {
        ...state,
        plans: action.payload,
      };
    case "SET_SELECTED_PLAN":
      return {
        ...state,
        selectedPlan: action.payload,
      };

    case "SET_PAYMENTHISTORY":
      return {
        ...state,
        paymentHistory: action.payload,
      };

    case "SET_MESSAGE":
      return {
        ...state,
        messages: action.payload,
      };

    case "SET_MESSAGE_COUNT":
      return {
        ...state,
        count: action.payload,
      };

    case "SET_ACTIVE":
      return {
        ...state,
        active: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
