import React, { useState,useEffect } from 'react'
import axios from "../axios"
import LoaderComponent from '../Loader';
import timestampConvert from '../services/timeStampConvert';
import useFetch from '../services/useFetch';

function SettlementVendor({vendor,month,year}) {
 const [settlementDetails,setSettlementDetails] = useState(null)
 const [adminSettledDetails,setAdminSettledDetails] = useState(null)
const [refId,setRefId] = useState('');
const handleSubmit = () =>{
    if(refId === ''){
        alert("Reference Id cannot be blank")
    }else{
        axios({method:"post","url":"/settlement",
        data:
        {id:adminSettledDetails?.id,
            amount:calaculateAmount(),
            referenceId:refId,
            settleDate:(Math.round(new Date().getTime()/1000))}}).then(res=>{
            console.log(res.data)
            if(res.data.status === 1){
                // cons(res.data.data)
                getSettleData()
            }   
    }).catch(e=>console.log(e))
}
}

//    const {data:settlementDetails,error,loading} = useFetch("/user/vendorRenewedUsersDetails?id="+vendor.user_id+"&month="+month+"&year="+year);
    
      useEffect(()=>{
    if(vendor){
            axios({method:"get","url":"/user/vendorRenewedUsersDetails?id="+vendor.user_id+"&month="+month+"&year="+year}).then(res=>{
                console.log(res.data)
                if(res.data.status === 1){
                    setSettlementDetails(res.data.data)
                }
            })
            getSettleData();
        
    }
},[vendor,month,year])
const getSettleData = ()=>{
    
    axios({method:"get","url":"/settlement/?id="+vendor.user_id+"&month="+month+"&year="+year}).then(res=>{
        console.log(res.data)
        if(res.data.status === 1){
            setAdminSettledDetails(res.data.data)
            if(res.data.data.reference_id)setRefId(res.data.data.reference_id)
        }
    })
}
    var calaculateAmount = ()=>{
        var total=0;
        if(settlementDetails ){

            settlementDetails.map(i=>
                {
                    total+=(i.amount*(vendor.commision/100))
                    console.log("amount",i)}
                    )
                }
        return total.toFixed(2);
     }
// if(loading) return <LoaderComponent />
// if(error) return <h3>Error</h3>  
    return (
              <tr>
      <td scope="row">1</td>
      <td>{vendor.name}</td>
 
      <td>{settlementDetails?.length}</td>
      <td>{calaculateAmount()}</td> 
      <td>{adminSettledDetails?.settled_date === 0 ? 'Not settled' : timestampConvert(adminSettledDetails?.settled_date) }</td>
      <td><input className="form-control" type="text" value={refId} onChange={(e)=>setRefId(e.target.value)} /></td>
      <td><button className="btn btn-success" onClick={handleSubmit} > Save </button></td>


    </tr>
    )
}

export default SettlementVendor
