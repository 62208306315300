import React, { useState } from "react";
import "./Register.css";
import { auth } from "./firebase";
import { Link, useHistory } from "react-router-dom";
import axios from "./axios";
import { useStateValue } from "./StateProvider";
function Register() {
  const [{}, dispatch] = useStateValue();
  const history = useHistory();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [name, setName] = useState();
  const [number, setNumber] = useState();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState();
  const signUp = async (e) => {
    e.preventDefault();
    auth
      .createUserWithEmailAndPassword(email, password)
      .then(async (auth) => {
        setLoading(true);
        console.log(auth);
        const data = {
          id: auth.user.uid,
          name: name,
          email: email,
          number: number,
        };
        const response = await axios({
          method: "post",
          url: "/user",
          data: data,
        });
        console.log(response);
        const response1 = await axios({
          method: "get",
          url: "/user",
          params: {
            id: auth.user.uid,
          },
        });
        dispatch({
          type: "SET_USERDETAILS",
          userDetails: response1.data,
        });
        history.push("/");
      })
      .catch((error) => setError(error.message));
    setLoading(false);
  };
  return (
    <div className="register">
      <div className="register__container">
        <div className="register__logo">
          <img src="assets/images/logo.png" />
        </div>
        <div className="register__title">
          <h3>Sign-Up</h3>
        </div>
        <div className="register__form">
          {error && (
            <div className="register__error">
              <p>{error}</p>
            </div>
          )}
          <form>
            <h6>Name</h6>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />

            <h6>Email</h6>
            <input
              type="text"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />

            <h6>Whatsapp Number</h6>
            <input
              type="text"
              value={number}
              onChange={(e) => setNumber(e.target.value)}
            />
            <h6>Password</h6>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <div className="register__signInContainer">
              <button
                onClick={signUp}
                type="submit"
                className="register__signInButton"
              >
                {loading ? "Loading" : "Sign Up"}
              </button>
            </div>
          </form>
        </div>

        <div className="register__register">
          <p>
            Already have an account?{" "}
            <Link className="register__registerText" to="/login">
              <strong>Login Here</strong>{" "}
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default Register;
