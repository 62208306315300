import { CloudDownloadOutlined, FileCopy } from "@material-ui/icons";
import React, { useRef, useState } from "react";
import "./ApiDocumentation.css";
import { useStateValue } from "./StateProvider";
import { baseurl } from "./global";
function ApiDocumentation() {
  const [{ userDetails }, dispatch] = useStateValue();
  const [copySuccess, setCopySuccess] = useState();
  const [copySuccess1, setCopySuccess1] = useState();

  const textAreaRef = useRef(null);
  const textAreaRef1 = useRef(null);

  const copyToClipboard1 = (e) => {
    textAreaRef1.current.select();
    document.execCommand("copy");
    e.target.focus();
    setCopySuccess1(true);
  };
  const copyToClipboard = (e) => {
    textAreaRef.current.select();
    document.execCommand("copy");
    //e.target.focus();
    setCopySuccess(true);
  };
  return (
    <div className="apiDocumentation">
      <div className="apiDocumentation__title">
        <h3>API Documentation</h3>
      </div>
      <div className="apiDocumentation__description">
        No complex steps required its very simlpe to integrate. And also no need
        to send "HI" from the mobile.
      </div>

      <div className="apiDocumentation__key">
        Your Key is
        <form>
          <textarea
            disabled
            rows={1}
            cols={1}
            ref={textAreaRef}
            value={userDetails?.api_key}
          />
        </form>
      </div>
      <div className="apiDocumentation__body">
        <h4>Please make a get request to the following url</h4>
        <form>
          <textarea
            disabled
            rows={1}
            cols={1}
            ref={textAreaRef1}
            value="https://online.apihub.co.in/whatsApi?apikey=<Your API KEY >&mobile=<Mobile number with Code>&message=<your message here>"
          />
        </form>
        <br></br>
        <h5>Mobile Number Example : 919090909090</h5>
        <br></br>
        <div className="apiDocumentation__title">
          <h3>Plan Integration</h3>
          <h5 style={{ color: "red" }}>
            Dont Forgot to add your IP in Manage Tab
          </h5>
          <a href={baseurl + "/apidoc"}>
            <CloudDownloadOutlined /> API Documentation
          </a>
        </div>
      </div>
    </div>
  );
}

export default ApiDocumentation;
