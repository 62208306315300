import React, { useEffect } from "react";
import "./Home.css";
import Manage from "./Manage";
import Plans from "./Plans";
import SideMenu from "./SideMenu";
import { useStateValue } from "./StateProvider";
import { timestampConvert } from "./reducer";
import PaymentHistory from "./PaymentHistory";
import Dashboard from "./Dashboard";
import ApiDocumentation from "./ApiDocumentation";
import Messages from "./Messages";
import {
  Route,
  Switch,
} from "react-router-dom";
import Users from "./adminComponents/Users";
import AddUser from "./adminComponents/AddUser";
import Settlements from "./adminComponents/Settlements";
import VendorSettlementPage from "./adminComponents/VendorSettlementPage";
function Home() {
  const [{ sideMenuActive }, dispatch] = useStateValue();

  return (
    <div className="home">
      <div className="home__container">
        <div className="home__left">
          <SideMenu />
        </div>
        <div className="home__right">
          
          <Switch> 
            <Route exact path="/dashboard" >
            <Dashboard />
              </Route>        
         
              <Route exact path="/dashboard/manage" >
            <Manage />
              </Route>  
              <Route exact path="/dashboard/plans" >
            <Plans />
            </Route>  

            <Route exact path="/dashboard/paymenthistory" >
            <PaymentHistory />
            </Route>  
            <Route exact path="/dashboard/apidocumentation" >
            <ApiDocumentation />
            </Route>  
            <Route exact path="/dashboard/messages" >
            <Messages />
            </Route>  
            <Route exact path="/dashboard/users/:role/:id" >
            <Users />
            </Route>
            <Route exact path="/dashboard/adduser/:role/:id" >
            <AddUser/>
            </Route>  
            <Route exact path="/dashboard/addvendor" >
            <AddUser isVendorUser={true}/>
            </Route>
              
            <Route exact path="/dashboard/settlements" >
            <Settlements />
            </Route>
            
            <Route exact path="/dashboard/vendorSettlements" >
            <VendorSettlementPage />
            </Route>
          </Switch>
        </div>
      </div>
    </div>
  );
}

export default Home;
