import React, { useEffect, useState } from "react";
import Pagination from "react-js-pagination";
import ReactPaginate from "react-paginate";
import axios from "./axios";
import { useStateValue } from "./StateProvider";
//import "bootstrap/dist/css/bootstrap.min.css";
import "./Messages.css";
import Modal from "react-bootstrap/Modal";
import ModalHeader from "react-bootstrap/ModalHeader";
import { timeandDateConvert } from "./reducer";
import LoaderComponent from "./Loader";
function Messages() {
  const [modalBody, setModalBody] = useState();
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);

  //const [messages, setMessages] = useState();
  const [{ userDetails, messages, active, count }, dispatch] = useStateValue();
  const pageLimit = 10;
  const [first, setFirst] = useState(true);
  const handlePageChange = async (number) => {
    dispatch({ type: "SET_ACTIVE", payload: number });

    //console.log();
    var off = number.selected * pageLimit;

    const data1 = {
      id: userDetails?.user_id,
      lim: pageLimit,
      off: off,
    };
    var response1 = await axios({
      url: "/messages",
      method: "post",
      data: data1,
    });
    dispatch({ type: "SET_MESSAGE", payload: response1.data });
    //setMessages(response1.data);
  };

  useEffect(()=>{
     handleApiCalls();
  },[])
  const  handleApiCalls = async ()=>{
    const _data = {
      id: userDetails?.user_id,
    };
    const _response = await axios({
      url: "/messages/count",
      method: "post",
      data: _data,
    });
    dispatch({ type: "SET_MESSAGE_COUNT", payload: _response.data.count });

    const _data1 = {
      id: userDetails?.user_id,
      lim: 10,
      off: 0,
    };
    const _response1 = await axios({
      url: "/messages",
      method: "post",
      data: _data1,
    });
    console.log("messafe", _response1.data);
    dispatch({ type: "SET_MESSAGE", payload: _response1.data });
   setLoading(false)
   }
  const sentMessage = async (number, message) => {
    var url =
      "/whatsApi?apikey=" +
      userDetails?.api_key +
      "&mobile=" +
      number +
      "&message=" +
      message;
    const response = await axios({
      url: url,
      method: "get",
    });
    setModalBody(response.data);
    setShow(true);
  };
  if(loading) return <LoaderComponent/>
  if(messages?.length ===0) return <h3>No items to display</h3>
  return (
    <div className="messages">
      <div className="messages__table">
        {messages?.length > 0 ? (
          <table>
            <tr>
              <th>SL NO:</th>
              <th>Number</th>
              <th>Message</th>
              <th>Status</th>
              <th>Date</th>

              <th>Action</th>
            </tr>
            {messages?.map((msg, i) => (
              <tr>
                <td>
                  {!active ? i + 1 : active?.selected * pageLimit + (i + 1)}
                </td>
                <td>{msg?.number}</td>
                <td>{msg?.message}</td>
                <td>
                  {msg?.status == 1 ? (
                    <div className="success">Success</div>
                  ) : (
                    <div className="failed">Failed</div>
                  )}
                </td>
                <td>{timeandDateConvert(msg?.date)}</td>
                <td>
                  <button
                    onClick={() => sentMessage(msg?.number, msg?.message)}
                  >
                    {" "}
                    Resent{" "}
                  </button>
                </td>
              </tr>
            ))}
          </table>
        ) : (
          <></>
        )}
      </div>
      <ReactPaginate
        previousLabel={"previous"}
        nextLabel={"next"}
        breakLabel={"..."}
        breakClassName={"break-me"}
        pageCount={count / pageLimit}
        marginPagesDisplayed={2}
        pageRangeDisplayed={5}
        onPageChange={handlePageChange}
        containerClassName={"pagination"}
        subContainerClassName={"pages pagination"}
        activeClassName={"active"}
      />
      <Modal
        size="sm"
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
      >
        <Modal.Header closeButton>
          <Modal.Title id="example-modal-sizes-title-sm">Response</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Status : <strong>{modalBody?.status} </strong> , Message :{" "}
          <strong>{modalBody?.message}</strong>{" "}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Messages;
