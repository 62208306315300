import React from "react"
import { useHistory } from "react-router";
import useFetch from "../services/useFetch"
import { useStateValue } from "../StateProvider"
function VendorDashboard() {
    const history = useHistory();
    const [{userDetails},dispatch] = useStateValue();
    let url = "/user/vendorUserCount?id="+userDetails?.user_id;
    const {data:countData,error,loading} = useFetch(url)
    const handleRoute = (filter)=>{
    history.push("/dashboard/users/"+userDetails?.role+"/"+userDetails?.user_id+"?filter="+filter)
    }
    if(error) return <h3>Eroor</h3>
    return (
        <div className="container">
                
        <div className="row">
            <div className="col-md-3">

            <button onClick={()=>handleRoute("")} type="button" class="btn btn-lg btn-primary">
  Total User Count <span class="badge badge-light">{countData?.userCount}</span>
  <span class="sr-only">users</span>
</button>
            </div>
            <div className="col-md-3">

<button onClick={()=>handleRoute("R")} type="button" class="btn btn-lg btn-primary">
  Renewed This Month <span class="badge badge-light">{countData?.lastMonthCount}</span>
  <span class="sr-only">unread messages</span>
</button>
</div>
<div className="col-md-3">

<button onClick={()=>handleRoute("E")} type="button" class="btn btn-lg btn-primary">
  Expired User Count <span class="badge badge-light">{countData?.expiredCount}</span>
  <span class="sr-only">unread messages</span>
</button>
</div>
<div className="col-md-3">

<button onClick={()=>handleRoute("A")} type="button" class="btn btn-lg btn-primary">
  Active Users <span class="badge badge-light">{countData?.userCount - countData?.expiredCount}</span>
  <span class="sr-only">unread messages</span>
</button>
</div>
</div>
<div className="row mt-3">

<div className="col-md-3">

<button onClick={()=>handleRoute("ES")} type="button" class="btn btn-lg btn-primary">
  Users Expires Shortly <span class="badge badge-light"></span>
  <span class="sr-only">unread messages</span>
</button>
        </div>
</div>        </div>
    )
}

export default VendorDashboard
