import { useState,useEffect } from "react";
import axios from "../axios";

export default function useFetch(url){
    const [data,setData] = useState(null)
    const [error,setError] = useState(null)
    const [loading,setLoading] = useState(true)
    useEffect(() => {
        async function init(){
            console.log(url)
            try{
                const res = await axios({method:"get",url:url});
                console.log(res.data)
                if(res.data.status === 1){
                    setData(res.data.data)
                }else{
                    throw res.data;
                }
            }catch(e){
                setError(e)
            }finally{
                setLoading(false);
            }
        }
        init()
    }, [])
return {data,error,loading}
}